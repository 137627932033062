import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "lib/BlLocale";
import {BundleEntry} from "components/bundle-entry/BundleEntry";
import {trim} from "ramda";
import {StatworxRecommendations} from "components/statworx-recommendations/StatworxRecommendations";

const target = document.getElementById('statworx-recommendations');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);
const num = parseInt(target?.dataset?.['num']!);
const trackingClass = target?.dataset?.['trackingClass']!;
const mobileSlider = target?.dataset?.['mobileSlider']!;
const blackFridayActive: boolean = target?.dataset?.['blackFridayActive'] === 'true';
const rawOrderId = target?.dataset?.['orderId'];
const orderId = rawOrderId != null ? parseInt(rawOrderId) : null;

if(target?.dataset?.['basket']) {
    const basket = target?.dataset?.['basket']!.split(",")?.map(trim)?.map(n => parseInt(n));
    ReactDOM.render(
        <BundleEntry
            bundleName="statworx-recommendations"
            locale={localeCode}
            prefetchTranslations={[
                'frontend.statworx.recommendations.title',
                'frontend.home.recommendations.title',
                'frontend.favorites.add',
                'frontend.favorites.remove'
            ]}
        >
            <StatworxRecommendations
                blackFridayActive={blackFridayActive}
                orderId={orderId!}
                num={num}
                basket={basket}
                trackingClass={trackingClass}
                mobileSlider={mobileSlider !== undefined}
            />
        </BundleEntry>,
        target
    );
}